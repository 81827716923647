<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.post.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <!--                <CButton color="primary" v-on:click="createTableRow" v-if='checkPermission("create-post")'-->
                <!--                         style="margin-right: 10px;">-->
                <!--                  {{ $lang.buttons.general.crud.create }}-->
                <!--                </CButton>-->
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                           v-on:click="exports(self,'pdf',module,'Post',self.userId)">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                                :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.post.filter.postType }}</label>
                  <v-select :options="postTypeList" v-model="post.postTypeId"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.post.filter.industry }}</label>
                  <v-select :options="industryOptions" v-model="post.industry"
                            @option:selected="getSubIndustry"></v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.post.filter.subIndustry }}</label>
                  <v-select :options="subIndustryOptions" v-model="post.subIndustry"></v-select>
                </div>
              </CCol>

              <!--              <CCol sm="3">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{ $lang.post.filter.resourceType }}</label>-->
              <!--                  <v-select :options="resourceTypeOptions" v-model="post.resourceType"></v-select>-->
              <!--                </div>-->
              <!--              </CCol>-->


              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.post.filter.duration }}</label>
                  <v-select :options="durationOptions" v-model="post.duration"></v-select>
                </div>
              </CCol>

              <!--              <CCol sm="3">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{ $lang.post.filter.priority }}</label>-->
              <!--                  <v-select :options="priorityOptions" v-model="post.priority"></v-select>-->
              <!--                </div>-->
              <!--              </CCol>-->

              <CCol sm="3" v-show="!iAmUser">
                <div class="form-group">
                  <label class="form__label">{{ $lang.post.filter.profileVerified }}</label>
                  <v-select :options="profileVerifiedOptions" v-model="post.profileVerified"></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{ $lang.post.filter.remoteWork }}</label>
                  <v-select :options="remoteWorkOptions" v-model="post.remoteWork"></v-select>
                </div>
              </CCol>


              <CCol sm="3" v-show="!iAmUser">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.post.filter.user
                    }}</label>
                  <v-select
                      :options="userOptions"
                      v-model="post.user"
                  ></v-select>
                </div>
              </CCol>

              <!--              <CCol sm="3">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{-->
              <!--                    $lang.post.filter.currency-->
              <!--                  }}</label>-->
              <!--                  <v-select-->
              <!--                    :options="currencyOptions"-->
              <!--                    v-model="post.currency"-->
              <!--                  ></v-select>-->
              <!--                </div>-->
              <!--              </CCol>-->


              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.post.filter.visibility
                    }}</label>
                  <v-select
                      :options="visibilityOptions"
                      v-model="post.visibility"
                  ></v-select>
                </div>
              </CCol>

              <!--              <CCol sm="6">-->
              <!--                <div class="form-group">-->
              <!--                  <label class="form__label">{{ $lang.post.filter.budget }}</label>-->
              <!--                  <VueSimpleRangeSlider-->
              <!--                      style="width: 300px"-->
              <!--                      :min="0"-->
              <!--                      :max="1000000"-->
              <!--                      :logarithmic="true"-->
              <!--                      v-model="range"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </CCol>-->
              <!--                        <CCol sm="3">-->
              <!--                            <div class="form-group">-->
              <!--                                <label class="form__label">{{$lang.post.filter.skills}}</label>-->
              <!--                                <v-select multiple :options="skillsOptions" v-model="post.skills"></v-select>-->
              <!--                            </div>-->
              <!--                        </CCol>-->

              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton> &nbsp;
                  <CButton color="danger" size="sm" @click="resetFilter()"
                           v-c-tooltip="$lang.common.filter.button.cancel">
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert :color="messageColor" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}</CAlert>

          <v-server-table :columns="columns" :url="listUrl" :options="options" ref="myTable"
                          @loaded="exports(self,'Excel',module,'Post',self.userId)">
            <template #status="data">
              <span v-if="data.row.status === 'Active'">
                <CBadge :color="statusBudget(data.row.status)">Open</CBadge>
              </span>
              <span v-if="data.row.status !== 'Active'">
                <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
              </span>
            </template>
            <template #description="data">
              <span :title="data.row.description">{{ trimfunction(data.row.description, 20) }}</span>
            </template>
            <template #userWithCompany="data">
              <Avatar
                  :image="data.row.avatar"
                  :defaultImage="data.row.avatar"
                  :content="data.row.userWithCompany"
                  :allowCompany="true"
                  :length="20"
              />
            </template>
            <template #actions="data">
              <!--v-if='checkPermission("view-post")'-->
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="postViewTableRow(data.row.id, data.row.userId)"
                  v-if="checkPermission('list-post')"
              >
                <i class="fas fa-eye"></i>
              </CButton>
              <CButton
                  color="danger"
                  v-c-tooltip="$lang.buttons.general.crud.delete"
                  v-on:click="deletePopup(data.row.id)"
                  v-if="checkPermission('delete-post')"
              >
                <i class="fas fa-trash"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>

  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  posts,
  industry,
  resourcetype,
  duration,
  currencytype,
  priority,
  worktype,
  users,
  skills,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-simple-range-slider/dist/vueSimpleRangeSlider.css";
import BeforeFilter from "../BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "PostScreen",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      selectedColumns: [],
      messageColor: 'success',
      iAmUser: "",
      userId: "",
      listUrl: "",
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      range: [0, 1000000],
      number: 1,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      postTypeList: [],
      industryOptions: [],
      subIndustryOptions: [],
      resourceTypeOptions: [],
      currencyOptions: [],
      visibilityOptions: [
        {
          value: 1,
          label: "Local",
        },
        {
          value: 2,
          label: "National",
        },
        {
          value: 3,
          label: "International",
        },
      ],
      durationOptions: [],
      priorityOptions: [],
      workTypeOptions: [],
      skillsOptions: [],
      selectedSkills: [],
      userOptions: [],
      remoteWorkOptions: [],
      profileVerifiedOptions: [],

      activePage: 1,
      module: posts,
      moduleIndustry: industry,
      moduleResource: resourcetype,
      moduleDuration: duration,
      moduleCurrency: currencytype,
      modulePriority: priority,
      moduleWorkType: worktype,
      moduleSkills: skills,
      moduleUser: users,
      post: {
        description: "",
        type: "",
        budget: "",
        isActive: "",
      },
      columns: [
        "userWithCompany",
        "postTypeName",
        "required",
        "noOfPosition",
        "requiredFor",

        "ratingLabel",
        "visibility",
        "status",
        "updatedAt",
        "actions",
      ],
      data: [],
      options: {
        headings: {
          userWithCompany: this.$lang.post.table.userName,
          ratingLabel: this.$lang.post.table.information,
          description: this.$lang.post.table.description,
          visibility: this.$lang.post.table.visibility,
          postTypeName: this.$lang.post.table.postType,
          budget: this.$lang.post.table.budget,
          status: this.$lang.post.table.status,
          noOfPosition: this.$lang.post.table.noOfPosition,
          requiredFor: this.$lang.post.table.requiredFor,
          required: this.$lang.post.table.required,
          // createdAt: this.$lang.common.table.createdAt,
          updatedAt: this.$lang.common.table.updatedAt,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: [
          "company",
          "description",
          "visibility",
          "postTypeName",
          "budget",
          "ratingLabel",
          "status",
        ],
        sortable: [
          "company",
          "description",
          "visibility",
          "postTypeName",
          "budget",
          "ratingLabel",
          "status",
        ],
        filterable: [
          "company",
          "description",
          "visibility",
          "postTypeName",
          "budget",
          "ratingLabel",
          "status",
        ],
      },
    };
  },
  created() {
    let self = this;
    self.listUrl = "/posts/list/";
  },
  mounted() {
    let self = this;
    // this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;

    self.iAmUser = localStorage.getItem("iAmUser");

    // let value = 'company';
    // self.columns = self.columns.filter(item => item !== value)


    self.userId = this.$route.params.user_id;
    if (typeof self.userId !== "undefined" || self.userId != null) {
      self.listUrl = this.listUrl + self.userId;
    } else {
      self.listUrl = this.listUrl;
    }


    //Filter Section
    self.postTypeList.push(
        {value: "1", label: "Required"},
        {value: "2", label: "Available"}
    );
    self.profileVerifiedOptions.push(
        {value: "1", label: "Pending"},
        {value: "2", label: "Verified"},
        {value: "3", label: "Blocked"}
    );

    self.remoteWorkOptions.push(
        {value: "Yes", label: "Yes"},
        {value: "No", label: "No"}
    );

    axios.get(this.listUrlApi(this.moduleIndustry))
        .then((response) => {
          response.data.data.map(function (value, key) {
            if (value.industryId === "") {
              self.industryOptions.push({value: value.id, label: value.industry});
            }
          });
        });

    // axios.get(this.listUrlApi(this.moduleResource))
    //     .then((response) => {
    //       response.data.data.map(function (value, key) {
    //         self.resourceTypeOptions.push({value: value.id, label: value.title});
    //       });
    //     });

    axios.get(this.listUrlApi(this.moduleDuration))
        .then((response) => {
          response.data.data.map(function (value, key) {
            self.durationOptions.push({value: value.id, label: value.title});
          });
        });

    axios.get(this.listUrlApi(this.moduleSkills))
        .then((response) => {
          response.data.data.map(function (value, key) {
            self.skillsOptions.push({value: value.id, label: value.title});
          });
        });
    // axios.get(this.listUrlApi(this.modulePriority))
    //     .then((response) => {
    //       response.data.data.map(function (value, key) {
    //         self.priorityOptions.push({value: value.id, label: value.title});
    //       });
    //     });
    // axios.get(this.listUrlApi(this.moduleCurrency))
    //     .then((response) => {
    //       response.data.data.map(function (value, key) {
    //         self.currencyOptions.push({value: value.id, label: value.title});
    //       });
    //     });

    axios.get(this.listUrlApi(this.moduleUser))
        .then((response) => {
          response.data.data.map(function (value, key) {
            self.userOptions.push({value: value.id, label: value.name});
          });
        });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);

    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {

    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    getSubIndustry() {
      let self = this;
      if (self.post.industry.value > 0) {
        const postData = {industry_id: self.post.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },

    applyFilter() {
      let self = this;
      let postTypeId = "";
      let industry = "";
      let subIndustry = "";
      let resourceType = "";
      let duration = "";
      let priority = "";
      let profileVerified = "";
      let currency = "";
      let visibility = "";
      let remoteWork = "";
      let skills = "";
      let user = "";
      self.selectedSkills = [];

      if (this.post.postTypeId != null) {
        postTypeId = this.post.postTypeId.value;
      }
      if (this.post.industry != null) {
        industry = this.post.industry.value;
      }
      if (this.post.subIndustry != null) {
        subIndustry = this.post.subIndustry.value;
      }
      if (this.post.resourceType != null) {
        resourceType = this.post.resourceType.value;
      }
      if (this.post.duration != null) {
        duration = this.post.duration.value;
      }
      if (this.post.priority != null) {
        priority = this.post.priority.value;
      }
      if (this.post.profileVerified != null) {
        profileVerified = this.post.profileVerified.value;
      }
      if (this.post.currency != null) {
        currency = this.post.currency.value;
      }
      if (this.post.visibility != null) {
        visibility = this.post.visibility.value;
      }
      if (this.post.remoteWork != null) {
        remoteWork = this.post.remoteWork.value;
      }
      if (this.post.user != null) {
        user = this.post.user.value;
      }
      if (this.post.skills != null) {
        skills = this.post.skills;
        skills.map(function (value, key) {
          self.selectedSkills.push({
            value: value.value,
          });
        });
      }

      const reqObj = {
        postType: postTypeId,
        industry: industry,
        subIndustry: subIndustry,
        resourceType: resourceType,
        duration: duration,
        priority: priority,
        profileVerified: profileVerified,
        currency: currency,
        visibility: visibility,
        remoteWork: remoteWork,
        user: user,
        range: this.range,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();

    },

    resetFilter() {
      this.post.postTypeId = null;
      this.post.industry = null;
      this.post.subIndustry = null;
      this.post.resourceType = null;
      this.post.duration = null;
      this.post.priority = null;
      this.post.profileVerified = null;
      this.post.currency = null;
      this.post.visibility = null;
      this.post.remoteWork = null;
      this.post.user = null;
      this.range = [0, 1000000];
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        if (result.value) {
          let self = this;
          axios.delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "success";
                  self.dismissCountDownS = 10;
                } else {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.message;
                  self.messageColor = "danger";
                  self.dismissCountDownS = 10;
                }
              }).catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.alertMessage = data;
            self.messageColor = "danger";
            self.dismissCountDownS = 10;
          });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "Post Create"});
    },
    postViewTableRow(item, userId) {
      this.$router.push({path: `posts/view/${item}/${userId}`});
    },
    editTableRow(item) {
      this.$router.push({path: `posts/edit/${item}`});
    },
    viewMediaTableRow(item) {
      this.$router.push({path: `posts/media/list/${item}`});
    },
    viewResponseTableRow(item) {
      this.$router.push({path: `posts/response/list/${item}`});
    },
    viewReportTableRow(item) {
      this.$router.push({path: `posts/report/list/${item}`});
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios.get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },
  },
};
</script>
